<template>
    <div class="privacybg">
        <div id="banner">
        <!-- MENU -->
        <div class="menu"> 
        <b-container id="menu" fluid>
            <div class="m-0">
              <div class="d-flex align-items-center">
                <b-container class="inside" fluid>
                    <b-row class="justify-content-between align-items-center text-wrapper">
                        <b-col sm="2" md="4" lg="5" class="phone">
                          <a :class="{'home': 'home' == this.$route.name}" :href="$router.resolve({ name: 'home'}).href"> <img src="@/assets/img/img_logo_logo.png"></a>
                        </b-col>
                        <b-col sm="5" md="8" lg="7" class=" phone menubanner ml-auto align-items-center   d-flex" style="justify-content: end;">
                            <div class="idioma me-4">
                              <b-button class="my-0 esp" :class="{ active: activeLanguage === 'esp' }" @click="setActiveLanguage('esp')">ESP </b-button>
                              <b-button class="my-0 eng"  :class="{ active: activeLanguage === 'eng' }" @click="setActiveLanguage('eng')">ENG</b-button>
                            </div>
 
                          <a href="#contacto">
                            <div class="centerv text-right">
                              <a :href="$router.resolve({ name: 'home2'}).href"><p class="my-0">{{ translations.menu.contact}}</p></a>
                            </div>
                          </a>
                        </b-col>
                    </b-row>
                </b-container>
          </div>
            </div>
        </b-container>
      </div>
 
      <div class="whatsapp-button">
          <SocialChat
          icon
        :attendants="attendants"
      >
        <p slot="header">{{ translations.whatsapp}} </p>
        <template v-slot:button>
          <img
            src="@/assets/img/Group 8.svg"
            alt="icon whatsapp"
            aria-hidden="true"
          >      
        </template>
        
      </SocialChat>
        </div>
         
 
        </div>
        <b-container class="privacy">
               <div class="container">
    <h1>{{translations.privacy_policy.title}}</h1>

    <p>{{translations.privacy_policy.sections[0].content}}</p>

    <div class="key-points">
        <h2>{{translations.privacy_policy.sections[1].title}}</h2>
        <p>{{translations.privacy_policy.sections[1].content}}</p>
        <ul>
            <li>{{translations.privacy_policy.sections[1].one}}</li>
            <li>{{translations.privacy_policy.sections[1].two}}</li>
        </ul>
    </div>

    <div class="key-points">
        <h2>{{translations.privacy_policy.sections[2].title}}</h2>
        <p>{{translations.privacy_policy.sections[2].content}}</p>
        <ul>
            <li>{{translations.privacy_policy.sections[2].one}}</li>
        </ul>
    </div>

    <div class="key-points">
      <h2>{{translations.privacy_policy.sections[3].title}}</h2>
        <p>{{translations.privacy_policy.sections[3].content}}</p>
        <p>{{translations.privacy_policy.sections[3].one}} <a href="mailto:andrea@quattromg.com">andrea@quattromg.com</a> {{translations.privacy_policy.sections[3].two}} </p>
 
        <ul>
          <li>{{translations.privacy_policy.sections[3].three}} </li>
            <li>{{translations.privacy_policy.sections[3].four}} </li>
            <li>{{translations.privacy_policy.sections[3].five}} </li>
            <li>{{translations.privacy_policy.sections[3].six}} </li>
            <li>{{translations.privacy_policy.sections[3].seven}} </li>
            <li>{{translations.privacy_policy.sections[3].eight}} </li>
            <li>{{translations.privacy_policy.sections[3].nine}} </li>
 
        </ul>
        <p>{{translations.privacy_policy.sections[3].ten}}</p>
    </div>

    <div class="key-points">
      <h2>{{translations.privacy_policy.sections[4].title}}</h2>
        <p>{{translations.privacy_policy.sections[4].content}}</p>
    </div>

    <div class="key-points">
      <h2>{{translations.privacy_policy.sections[5].title}}</h2>
        <p>{{translations.privacy_policy.sections[5].content}}</p>
    </div>

    <div class="key-points">
      <h2>{{translations.privacy_policy.sections[6].title}}</h2>
        <p>{{translations.privacy_policy.sections[6].content}}</p>
        <ul>
            <li>{{translations.privacy_policy.sections[6].one}}</li>
        </ul>
        <p>{{translations.privacy_policy.sections[6].two}}</p>
    </div>

    <div class="key-points">
      <h2>{{translations.privacy_policy.sections[7].title}}</h2>
        <p>{{translations.privacy_policy.sections[7].content}}</p>
 
    </div>
    <p class="updated"> {{translations.privacy_policy.last_updated}}</p>
</div>

          


        </b-container>
    </div>
</template>

<script>
import { SocialChat } from 'vue-social-chat'
  import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';
  export default {
    components: {
       SocialChat
    },
data() {
return {
    color: '#01326C',
    activeLanguage: 'esp',
    translations: {},
    attendants: [
          {
            app: 'whatsapp',
            label: '',
            name: 'Edgar Solorio',
            number: '526861407554',
            avatar: {
              src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
              alt: 'Edgar Solorio avatar'
            }
          },]
 
};
},  mounted() {
     
  const savedLanguage = localStorage.getItem('selectedLanguage');
  if (savedLanguage) {
    this.setActiveLanguage(savedLanguage);
  } else {
    this.translations = this.activeLanguage === 'esp' ? esp : en;
  }
     
  },
methods: {
 setActiveLanguage(Language) {
        this.activeLanguage = Language;
        this.translations = Language === 'esp' ? esp : en;
        localStorage.setItem('selectedLanguage', Language);
      },
 
},
};
</script>



<style scoped>

body {
      
            background-color: #E4DCD7 !important;
        }
        .container {
            width: 100%;
            text-align: justify;
 
            padding-top:  4%;
            padding-bottom:2%;
            color: black;
        }
        h1 {
            color: black;
        }
        .updated {
            text-align: right !important;
            font-style: italic !important;
        }
        a {
            color: black;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
        .key-points {
            padding: 10px;
            margin: 20px 0;
        }
        .key-points h2 {
            margin: 0 0 10px 0;
        }

        @media(max-width: 985px){
            .container {
 
            padding-top:  13%;
     
            }
        }
*{
  text-decoration: none;
 
}

.privacy, .privacybg{
  background-color: #E4DCD7;
}
#thank-you-modal h4, #thank-you-modal button, #thank-you-modal, #error-modal button, #informacion-modal button, #informacion-modal, #error-modal{
  font-family: "Manrope", sans-serif;
}

.btn-info, .btn-fail, .btn-success{
  background-color: transparent;
  border-style: none;
  color:#FD663B;
}

.btn-info:hover, .btn-fail:hover, .btn-success:hover{
  background-color:transparent;
  border-style: none;
  color:#FD663B;
}


#thank-you-modal .modal-title span {
  color: #FD663B;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}
#error-modal .modal-title span {
  color: #FD663B;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}
#informacion-modal .modal-title span {
  color: #FD663B; 
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}


*{
  text-decoration: none;
}

a{
  z-index: 400;
  position: relative;
}
span{
  font-weight: 400;
  font-size: 20px;
  line-height: 27.32px;
  color:rgba(78, 73, 73, 0.589) ;
}

#banner .text-wrapper, #servicios .text-wrapper, #contacto .text-wrapper, #menu .text-wrapper{
    max-width: 83%;
    margin: auto;
}
/* MENU */

#menu{
  max-width: 100%;
  width: 100%;
  position: relative;
    height: auto;
    width: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#menu .row {
  display: flex;
}
.inside .centerv p{
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.inside img{
  width: 188px;
}

/* BANNER */

#banner{
    position: relative;
    background-color: #E4DCD7;
    height: auto;
    width: auto;
    text-align: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 7%;
}

#img-intro{
  background-image: url("../assets/img/img_intro.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  max-height: 550px;
  height: auto;
  margin-top: -6%;
  z-index: 200;
  padding-bottom: 500px;
}


#banner h6{
  font-weight: 800;
  font-size: 200px;
  line-height: 200px;
  color: #FD663B;
  padding: 0;
  z-index: 200;
}

#banner .row,
#banner .col{
  display: flex;
}


#banner h2{
  font-size: 75px;
  line-height: 85px;
  font-weight: 600;
  color:#000000;
  padding-bottom: 7%;
}

#banner h1{
  font-size: 80px;
  line-height: 100px;
  font-weight: 400;
  color:#010B2E;
  padding-bottom: 13%;
}

#banner h3{
  font-size: 40px;
  line-height: 70px;
  font-weight: 400;
  padding-top: 10%;
  padding-bottom:9%;
}

#banner h5{
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #FD663B;
  margin-bottom: 0 !important;
}

.privacy .container p,.privacy .container ul{
  font-size: 20px;
  line-height: 27.32px;
  font-weight: 400;
}

.privacy .container h2{
  font-size: 25px;
  line-height: 34.15px;
  font-weight: 600;
}

.privacy .container h1{
  font-size:40px;
  line-height: 40px;
  font-weight: 600;
}
.conoce-mas{
    text-align: center;
    display: flex;
    justify-content: center;
    border: 2px solid #FD663B;
    border-radius: 2000px;
    width:156px;
    height: 156px;
    align-items: center;
    background-color: transparent;
}


.text-right{
    text-align: center;
    display: flex;
    justify-content: center;
    border-style: none;
    border-radius: 100px;
    width:217px;
    height: 64px;
    align-items: center;
    background-color: #000000;
}
.whatsapp-button img {
    width: 66px;
    height: 66px;
    margin-left: -3px;
    transition: transform 0.3s ease-in-out;
}

.whatsapp-button img:hover {
    transform: scale(1.1);
}

#banner p{
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color:#FFFFFF;
}

.virtual-think{
  z-index: 300;
}
.idioma{
  border: 1px solid #FD663B;
  width: 200px;
  height: 64px;
  border-radius: 100px;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}
/* QUE ES */

#que-es{
    position: relative;
    background-color: #FD663B;
    background-image: url("../assets/img/img_graphic.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    height: 705px;
    align-items: center;
    display: flex;
    z-index: -2;
    width: auto;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#que-es .text-wrapper{
  width: 65%;
  margin: auto;
}

#que-es h2{
  font-size: 50px;
  font-weight: 600;
  line-height: 68.3px;
  color: #000000;
}

/* TRANSFORMAR */ 

#transformar{
  position: relative;
  background-image: url("../assets/img/img_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 669px;
  text-align: left;
  padding-left: 0 !important;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 0 !important;
}

#transformar .text-wrapper{
  width: 100%;
  padding-left: 8.5%;
  padding-right: 6%;
}


#transformar h2{
  font-size: 45px;
  font-weight: 600;
  line-height: 55px;
  color: #FFFFFF;
}

#transformar h3{
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  color: #FFFFFF;
}

#transformar h5{
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}


.ver-muestra{
    text-align: center;
    display: flex;
    justify-content: center;
    border: 2px solid #FFFFFF;
    border-radius: 100px;
    width:156px;
    height: 156px;
    align-items: center;
    background-color: transparent;
}

.muestra{
  padding-left: 10%;
}
/* SERVICIOS  */ 

#servicios{
    position: relative;
    background-color: #E4DCD7;
    height:auto;
    z-index: 20;
    width: auto;
    text-align: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 4%;
}
.menu{
  top:0;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  position: fixed;
  max-width: 100%;
  z-index: 440;
  width: 100%;
  background-color: #E4DCD7;
}

#servicios h2{
  font-size: 45px;
  font-weight: 600;
  line-height: 61.47px;
  color: #000000;
  padding-top: 3%;
}

.offer-wrapper p{
  font-size: 20px;
  line-height: 27.32px;
  font-weight: 400;
  color:#000000;
  margin-top: 20px;
  margin-bottom: 40px;
}

.offer-wrapper h4{
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  color:#010C2D;
  margin-top: 20px;
}

#servicios .text-wrapper .heading{
  padding: 0;
}

.offer-wrapper{
  background-color: #FD663B;
  margin-right: 15px;
  height: 499px;
  padding-top: 3%;
  padding-left: 45px;
  padding-right:45px;
  margin-bottom: 15px;

  width: 391px;
}


.embeded .hola{
  margin-bottom: 20px;
}

.embeded{
  padding-bottom: 6%;
  z-index: 10;
}

.offersrow{
  padding-bottom: 5%;
}
/* CONTACTO */

#contacto{
    position: relative;
    background-color: #FD663B;
    background-image: url("../assets/img/img_graphic_contact.svg");
    background-repeat: no-repeat;
    background-position: left 0% top 10%;
    height: auto;
    width: auto;
    text-align: left;
    padding-bottom: 7%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#contacto .text-wrapper{
  display: flex;
}
#contacto h1{
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
  color: #000000;
  width: 60%;
}

#contacto h2{
  font-size: 20px;
  line-height: 27.32px;
  font-weight: 400;
  color: #000000;
}

.height{
  height: 180px;
}

/* FORM */ 

form{
  margin-top: -12%;
}

  .form-control{
    background-color: transparent ;
    color: #00000080 ;
    margin-bottom: -5px;
    border: none;
  }

  .form-control:focus{
    background-color: transparent ;
    border: none;
  }

  .form-floating{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    line-height: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 18px;
    border-bottom: 1px solid #000000;
  }
  .form-floating > .form-control{
    height: 52px;
    position: relative;
    color: #000000;
    padding-left: 0px;
    margin-left: 0px;
    z-index: 1;
    border:none;
    background-color: transparent ;
    line-height: 20px;
    font-size: 20px;
    padding: 35px 0px 20px 0px;
    font-weight: 500;
  }

  .hola .row > *{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .form-floating > label {
    line-height: 20px;
    font-size: 20px;
    padding-left: 0px;
    margin-left: 0px;
    padding-bottom: 0px ;
    margin-bottom: 0px;
    font-weight: 400;
    z-index: 10;
    color: #00000080;
    border: none;
  }

  .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after{
      background: transparent;
      color: #000000;
      font-weight: 400;
      padding-left: 0px;
      margin-left: 0px;
      font-size: 20px;
      line-height: 20px;
      border: none;
      margin-bottom: 0px;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
      -webkit-transform:translateY(-20%) translateX(0rem);
      transform: translateY(-20%) translateX(0rem);
      z-index: 20;
      color: #000000;
      font-weight: 400;
      border: none;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 0px;
  }

  .english{
    max-width: 650px;
  }
  input:focus,
  input:active{
      outline: none ;
      margin-bottom: 0px;
      box-shadow: none ;
      border: none;
  }

  .boton{
    background-color: #000000;
    border-style: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    width: 183px;
    margin-top:32px;
    height: 51px;
  }

  .boton:hover{
    background-color: #000000;
  }

  .forma{
    max-width: 494px; 
    text-align: end;
  }

  .idioma .btn.btn-secondary{
    background-color: transparent;
    border: none;
    color:#FD663B;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .idioma .btn.active.esp{
    background-color: #FD663B;
    color: #FFFFFF;
    height: 64px;
    border-radius: 100px;
    margin-right: 0;
    width: 100px;
    margin-left:-14px;
    margin-right: 20px;
  }

  .idioma .btn.active.eng{
    background-color: #FD663B;
    color: #FFFFFF;
    height: 64px;
    border-radius: 100px;
    margin-right: 0;
    width: 100px;
    margin-left:20px;
    margin-right: -18px;
  }
  /* MEDIA QUERIES */ 

  @media (max-width: 1490px){

    #banner h6{
      font-size: 199px;
    }

    #banner h2{
      padding-bottom: 3%;
    }
    .muestra{
  margin-bottom: -4%;
}
  }

  @media (max-width: 1483px){

#banner h6{
  font-size: 195px;
}
}

@media (max-width: 1460px){

#banner h6{
  font-size: 190px;
}

#banner h2{
  font-size: 72px;
}

#servicios .col-sm-8 {
  width: 70%;
}

#transformar .col-sm-8{
  width: 70%;
}


}

@media (max-width: 1418px){

#banner h6{
  font-size: 185px;
}

#banner h2{
  font-size: 70px;
  line-height: 80px;
  
}
#servicios .col-sm-8 {
  width: 70%;
}
}

@media (max-width: 1382px){

#banner h6{
  font-size: 180px;
}

#banner h2{
  font-size: 68px;
  line-height: 78px;
}

}

@media (max-width: 1353px){

  #servicios .col-sm-8 {
  width: 73%;
}
}

@media (max-width: 1345px){

  #banner h6{
  font-size: 177px;
}



}

@media (max-width: 1325px){
  #banner h2{
  font-size: 66px;
  line-height: 76px;

}

#banner h6{
  font-size: 174px;
}

.muestra{
  margin-bottom: -7%;
}

}

@media (max-width: 1302px){
  #servicios .col-sm-8 {
  width: 77%;
}
#banner h6{
  font-size: 170px;
}

#banner h2{
      padding-bottom: 2%;
    }
}

@media (max-width: 1274px){
  #contacto h1{
    width: 65%;
  }

  #banner h6{
  font-size: 166px;
}

#img-intro{
  margin-top: -7%;
}
}

@media(max-width: 1244px){
  #banner h6{
  font-size: 163px;
}
#servicios .col-sm-8 {
  width: 80%;
}
}

@media(max-width: 1223px){
  #banner h6{
  font-size: 159px;
}


}

@media(max-width: 1195px){
  #servicios .col-sm-8 {
  width: 90%;
}

#que-es h2{
    font-size: 45px;
    line-height: 63.3px;
}

#contacto h2, span, .offer-wrapper p{
  font-size: 16px;
    line-height: 24.32px;
}


.form-floating > label{
    line-height: 18px;
    font-size: 16px;
}
#contacto .col-sm-4 {
    width: 40%;
}

#menu .col-3{
    width: 32%;
}

.offer-wrapper {
    height: auto;
}

#banner h6{
  font-size: 150px;
}
}
@media(max-width: 1129px){
  #banner h6{
  font-size: 135px;
}
#servicios .col-sm-8 {
  width: 100%;
}

.muestra {
   padding-left: 0; 
}

#img-intro{
  margin-top: -8%;
}
#contacto h1{
  width: 90%;
  max-width: 100%;
}

  #que-es h2 {
    font-size: 40px;
    line-height: 55px;
}
.offer-wrapper{
  width: 350px !important;
  flex: none;
}
}

@media(max-width: 1056px){
  #banner h6{
  font-size: 130px;
}

#transformar h2 {
    font-size: 35px;
    line-height: 50px;
}
#transformar h3 {
  font-size: 20px;
  line-height: 25px;
}


.ver-muestra  {
    width: 140px;
    height: 140px
}


.offer-wrapper{
  width: 350px !important;
  flex: none;
}


#que-es h2{
    font-size: 35px;
    line-height: 45px;
}
#img-intro{
  margin-top: -9%;
}
}

@media(max-width: 1009px){
  #banner h6{
  font-size: 130px;
}

  .offer-wrapper{
  width: 350px !important;
  flex: none;
}

#contacto h1{
  width: 90%;
  max-width: 100%;
}





}


@media(max-width: 985px){
  #banner h6{
  font-size: 120px;
}

#img-intro{
  margin-top: -10%;
}

#contacto h1{
  width: 90%;
  max-width: 100%;
}
.form-floating > label{
    line-height: 18px;
    font-size: 13px;
}





}

@media(max-width:918px){
  #banner h6{
  font-size: 110px;
}
#img-intro{
  margin-top: -11%;
}

#banner h2, #banner .col-sm-3.pt-4{
  padding-top: 6% !important;
}
.offer-wrapper{
  width: 290px !important;
  
}
#banner h2{
  padding-bottom: 0px;
}
#contacto h1{
  width: 90%;
  max-width: 100%;
}

form{
  padding-top: 5%;
}

}

@media(max-width:868px){

  .menubanner{
    flex-direction: column-reverse;
  }
 

  .phone{
    width: 50%;
  }


  .idioma.me-4{
    margin-right: 0 !important;
    margin-top: 7px;
  }

  .idioma{
    width: 90px;
    height: 32px;
  }
  .idioma .btn{
    font-size: 13px !important;
        line-height: 20px;
  }
  .idioma .btn.active.eng, .idioma .btn.active.esp{
    margin:0;
    height: 32px;
    width: 51px
  }

  .text-right{
    width: 190px;
  }


  #banner h2 {
    font-size: 58px;
    line-height: 65px;
}
#banner h6{
  font-size: 110px;
}
#img-intro{
  margin-top: -12%;
}

#que-es h2 {
    font-size: 30px;
    line-height: 40px;
}

#transformar h2  {
    font-size: 30px;
    line-height: 40px;
}
#transformar h3  {
    font-size: 17px;
    line-height: 23px;
}

.offer-wrapper{
  width: 295px !important;
}

#contacto h1{
  font-size: 65px;
  line-height: 65px;
}
.form-floating > label{
    line-height: 16px;
    font-size: 14px;
}

}

@media(max-width:840px){
  #banner h6{
  font-size: 100px;
}

#contacto h1 {
    width: 100%;
    max-width: 100%;
}

#contacto .col-sm-4 {
    width: 50%;
}

#img-intro{
  margin-top: -13%;
}

#banner h5{
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: #FD663B;
    margin-bottom: 0 !important;
}

.conoce-mas{
  width: 130px;
  height: 130px;
}

.forma{
  max-width: 520px;
  
}

#contacto .col-sm-5{
  width: 50%;
}
}


@media(max-width:767px){
  #banner h6{
  font-size: 90px;
}
#banner h2, #contacto h1 {
    font-size: 50px;
    line-height: 55px;
}
.offer-wrapper{
  width: 280px !important;
}

#img-intro{
  margin-top: -14%;
}
}
@media(max-width:727px){

.offersrow{
  justify-content: center;
}
#banner{
  padding-top: 12%;
}
.offer-wrapper{
  width: fit-content !important;
  height: fit-content !important;
  margin-right: 10px;
  margin-left: 10px;
}
#banner h6 {
  font-size: 82px;
  line-height: 192px;
}
 #banner h5 {
    font-size: 16px;
    line-height: 20px;
}

.conoce-mas {
  width: 110px;
  height: 110px;
}

#contacto .text-wrapper.justify-content-between{
  flex-direction: column;
}

#contacto .col-sm-4, #contacto .col-sm-5{
    width: 100%;
}

.btn{
  margin-bottom: 20%;
}

#contacto .text-wrapper.pt-5{
  padding-top: 5% !important;
}

form{
  padding-top: 10%;
}
.menu{
  padding-top: 5%;
  padding-bottom: 5%;
}


}


@media(max-width:638px){

  #banner .text-wrapper.transporta .col{
    flex: 1 0 0%;
  }


#banner{
  padding-top: 12%;
}








  #banner h6 {
  font-size:60px;
  line-height: 142px;
}
#banner .col-sm-9{
  width: 100% !important;
}
 


#servicios h2, #contacto h1{
  font-size: 35px;
  line-height: 40px;
}

#contacto .col-sm-4 {
    width: 70%;
}

#transformar .col-sm-8  {
    width: 100%;
}

#transformar h2  {
    font-size: 25px;
    line-height: 33px;
}
.muestra{
  padding-left: 65%;
  margin-top: 0;
}
.ver-muestra {
  width: 110px;
  height: 110px;
}
#transformar h5 {
    font-size: 16px;
    line-height: 20px;
  
}
#transformar .funciones {
    width: 55% !important;
    margin-top: -12%;
}

#transformar .text-wrapper {
    width: 90%;
    padding-left: 8.5%;
    padding-right: 0;
}
.inside img {
    width: 146px;
}

#banner p, #banner h5, #transformar h5{
    font-size: 15px;
    line-height: 20px;
}
.text-right {
    width: 160px;
    height: 52px;
}

}


/* TELEFONO */ 
@media(max-width:520px){

  #banner .text-wrapper, #servicios .text-wrapper, #contacto .text-wrapper, #menu .text-wrapper {
    max-width: 90%;
    margin: auto;
 
}





.inside .text-wrapper{
    max-width:100% !important;
  }



.text-right {
    width: 120px;
    height: 42px;
}

 
#banner h2   {
    font-size: 40px;
    line-height: 45px;
}
 

.conoce-mas, .ver-muestra{
  width: 110px;
  height: 110px;
}

#banner .col-sm-3.pt-4{
  padding-top: 0 !important;
}
 
#banner h6 {
  font-size: 52px;
  line-height: 52px;
}
#img-intro{
  margin-top: -7%;
  padding-bottom: 220px;
}#contacto .col-sm-4 {
    width: 100%;
}
#que-es h2  {
    font-size: 22px;
    line-height: 30px;
}
#que-es {
    background-position: 50%;
    background-size: contain;
    height:auto;
    padding-top: 20%;
    padding-bottom: 20%;
}
#que-es .text-wrapper {
    width: 80%;
    margin: auto;
}

#transformar .col-sm-8  {
    width: 100%;
}

#transformar h2  {
    font-size: 25px;
    line-height: 33px;
}
.muestra{
  padding-left: 69%;
  margin-top: -0%;
}

#banner .text-wrapper.transporta .col{
flex: 0 0 auto;
}
#transformar .funciones {
    width: 87% !important;
    margin-top: 0%;
}

#transformar .text-wrapper {
    width: 94%;
    padding-left: 8.5%;
    padding-right: 0;
}
#transformar h3  {
    font-size: 17px;
    line-height: 23px;
    width: 75%;
    margin-top: -16%;
}

#transformar .col-sm-8.pb-5.mb-5{
  padding-bottom: 3% !important;
}
 
#contacto {
    background-position: left -45% top 10%; 
}

.hola iframe{
  width:310px;
}
}


</style>
