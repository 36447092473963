<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
#app {
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: black;
  text-decoration: none;
  --vsc-bg-header: #333; /* Background color of the header */
  --vsc-bg-footer: transparent; /* Background color of the footer */
  --vsc-text-color-header: white; /* Text color of the header */
  --vsc-text-color-footer: black; /* Text color of the footer */
  --vsc-bg-button: transparent; /* Background color of the WhatsApp button */
  --vsc-text-color-button:#FD663B; /* Text color of the WhatsApp button */
  --vsc-outline-color: transparent; /* Outline color of the WhatsApp button */
  --vsc-border-color-bottom-header: transparent; /* Border color of the bottom header */
  --vsc-border-color-top-footer: transparent; /* Border color of the top footer */
}

.vsc-popup-body__link-avatar {
    display: none;
}
 
</style>
