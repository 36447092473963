import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '../components/HelloWorld'
import PrivacyPolicy from '../components/PrivacyPolicy'
 
//import About from '../components/About'


Vue.use(Router)


export default new Router({
  mode: 'history',
  routes: [
    {
        path: '/',
        name: 'home',
        component: HelloWorld
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: PrivacyPolicy
    },
    {
        path: '/#contacto',
        name: 'home2',
        component: HelloWorld
      } 
 
  ]
})

